<script setup>
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { DataLangSource } from "@/plugins/i18n-utils.js";

// i18n
const { locale } = useI18n();

const lang = computed(() => {
    const value = DataLangSource.filter(its => its.locale === locale.value);
    const result = value.length > 0 ? value[0] : DataLangSource[0];
    return result.short;
});
</script>

<template>
    <main class="main-box">
        <div class="set-box">
            <picture>
                <source media="(max-width: 992px)" srcset="/img/maintenance-bg-m.jpg" />
                <img src="/img/maintenance-bg.jpg" alt="Maintenance background" />
            </picture>
            <div class="text-box" v-bind:lang="lang">
                <span class="title">
                    {{ $t("error.maintenance.title") }}
                </span>
                <span class="subtitle">
                    {{ $t("error.maintenance.subtitle") }}
                </span>
            </div>
        </div>
    </main>
</template>

<style lang="scss" scoped>
.main-box {
    @include flex(column, center, center);
    height: 100vh;
    width: 100%;

    .set-box {
        position: relative;

        .text-box {
            @include flex(column, center, center);
            color: #fff;
            height: 110px;
            width: 476px;
            position: absolute;
            left: 50%;
            bottom: 30px;
            transform: translate(-50%, 0);

            // Variables
            --title-size: 34px;
            --subtitle-size: 12px;

            &[lang="zh-TW"],
            &[lang="zh-CN"],
            &[lang="th-TH"],
            &[lang="ko-KR"] {
                --title-size: 60px;
                --subtitle-size: 40px;
            }

            &[lang="en-US"],
            &[lang="vi-VN"],
            &[lang="hi-IN"] {
                --title-size: 48px;
                --subtitle-size: 26px;
            }

            &[lang="id-ID"],
            &[lang="pt-BR"] {
                --title-size: 48px;
                --subtitle-size: 22px;

                &>* {
                    margin-bottom: 0.5rem;
                }
            }

            &[lang="ja-JP"] {
                --title-size: 42px;
                --subtitle-size: 32px;
            }

            .title {
                font-size: var(--title-size);
                word-break: keep-all;
            }

            .subtitle {
                font-size: var(--subtitle-size);
                word-break: keep-all;
            }
        }
    }
}

@include big {
    .main-box {
        .set-box {
            .text-box {
                height: 52px;
                width: 232px;
                bottom: 14px;

                &[lang="zh-TW"],
                &[lang="zh-CN"],
                &[lang="hi-IN"] {
                    --title-size: 34px;
                    --subtitle-size: 12px;
                }

                &[lang="en-US"],
                &[lang="vi-VN"],
                &[lang="th-TH"],
                &[lang="ko-KR"] {
                    --title-size: 22px;
                    --subtitle-size: 12px;
                }

                &[lang="ja-JP"] {
                    --title-size: 20px;
                    --subtitle-size: 16px;
                }

                &[lang="id-ID"] {
                    --title-size: 20px;
                    --subtitle-size: 12px;
                }

                &[lang="pt-BR"] {
                    --title-size: 20px;
                    --subtitle-size: 12px;
                }
            }
        }
    }
}
</style>
